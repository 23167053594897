import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './VideoCarousel.module.scss';

const CustomLeftArrow = ({ onClick }) => (
  <button className={`${styles['carousel-arrow']} ${styles['carousel-arrow--left']}`} onClick={onClick}>
    ‹
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button className={`${styles['carousel-arrow']} ${styles['carousel-arrow--right']}`} onClick={onClick}>
    ›
  </button>
)

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const VideoCarousel = () => {
  const videos = [
    "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4",
    "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4",
    "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4",
    "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4",
    "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4",
    "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4",
    "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4",
    "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4",
    "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4",
    "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4"
  ];

  return (
    <Carousel
      responsive={responsive}
      containerClass={styles['carousel-container']}
      itemClass={styles['carousel-item-padding']}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      
    >
      {videos.map((video, index) => (
        <div key={index} className={styles['video-wrapper']}>
          <video className={styles['video-item']} controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500</p>
        </div>
      ))}
    </Carousel>
  );
};

export default VideoCarousel;
